@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

.app-container {
    background-color: #111111;
    font-family: "Inter", sans-serif;
    overflow-x: hidden;
    transition: 0.5s ease-in-out;
    padding: 1rem 4rem;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
    .app-container {
        padding: 0;
    }

    ::-webkit-scrollbar {
        display: none;
    }
}

::-webkit-scrollbar {
    background-color: #111111;
    width: 0.3rem;
}

::-webkit-scrollbar-thumb {
    background-color:rgb(93, 93, 209);
}

.light {
    background-color: #ffffff !important;
}