.free {
    position: relative;
    margin-bottom: 1rem;
    height: auto;
}

.container {
    display: flex;
    background-color: #ff8139;
    position: relative;
    height: auto;
    border-radius: 1rem;
    overflow: hidden;
    z-index: 1;
    padding-bottom: 1rem;
    height: auto;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('https://img.freepik.com/free-photo/illuminated-city-street-reflects-wet-canal-water-generated-by-ai_188544-33974.jpg?t=st=1701087309~exp=1701090909~hmac=e4531b0b9f5983cd70c32c6fcc7b4f268fb4cc45c02b93bce7072290bd7a713c&w=740');
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    padding-left: 5rem;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: justify;
    text-justify: inter-word;
    padding-right: 3.5rem;
    height: auto;
    color: white;
}

.description {
    width: 60%;
    margin-top: 1rem;
    padding-right: 0;
}

.image {
    display: flex;
    align-items: center;
    width: 60%;
}

.line {
    position: relative;
    display: inline-block;
    /* width: 10.3rem; */
    width: 25%;
    height: 1px;
    background-color: white;
    margin-bottom: 0.6rem;
}

.line2{
    position: relative;
    display: inline-block;
    /* width: 10.3rem; */
    width: 15%;
    height: 1px;
    background-color: white;
    margin-bottom: 0.6rem;
}

.title {
    font-size: 2rem;
    text-align: center;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.heading {
    margin: 0 1rem;
    font-size: 2.5rem;
    color: #ff8139;
}

.heading2{
    margin: 0 1rem;
    font-size: 2.5rem;
    color: #ff8139;
    text-transform: uppercase;
}

.cards {
    display: flex;
    position: absolute;
    top: -2rem;
    right: 40%;
}

.card1 {
    top: 6rem;
    left: 1.5rem;
    position: absolute;
    /* transform: rotate(-13deg); */
    z-index: 3;
    font-size: 0.6rem;
    display: flex;
}

.card1 h4,
.card2 h4 {
    text-align: center;
}

.card1 .card-image img {
    height: 10rem;
    width: 12rem;
}

.card2 {
    top: 10rem;
    z-index: 2;
    position: absolute;
    left: 15rem;
    /* transform: rotate(13deg); */
    font-size: 0.7rem;
}

.card2 .card-image img {
    height: 10rem;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
    .free {
        margin: 0;
        margin-bottom: 0;
    }

    .container {
        padding: 1rem 2rem;
        height: auto;
        border-radius: 0;
    }

    .pink {
        left: -70%;
    }

    .green {
        right: -100%;
    }

    .content {
        padding: 0;
        font-size: 0.8rem;
        margin-top: 1rem;
    }

    .image {
        width: 100%;
    }

    .title {
        font-size: 22px;
    }

    .cards .card1,
    .cards .card2 {
        zoom: 0.5;
    }

    .cards {
        right: 59%;
    }

    .description {
        width: 100%;
    }

    .heading {
        font-size: 2rem;
    }

    .heading2{
        font-size: 1.8rem;
    }

    .title {
        width: 100%;
    }

    .line {
        display: none;
    }

    .line2 {
        display: none;
    }
}