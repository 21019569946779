.scrollToTop {
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    position: fixed;
    bottom: 5%;
    right: 5%;
    border-radius: 5rem;
    background-color: red;
    height: 4rem;
    width: 4rem;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ff3998, #ff8139);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #111111 inset;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrollToTop:hover {
    box-shadow: none;
}

.scrollToTop a svg {
    font-size: 2rem;
    color: #ffffff;
}

.visible {
    visibility: visible;
    opacity: 1;
}

.light {
    box-shadow: 2px 1000px 1px #ffffff inset !important;
}

.light:hover {
    box-shadow: none !important;
}

.light a svg {
    color: #000000 !important;
}