.like {
    margin: 2rem 0;
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 2rem;
    text-align: justify;
    text-justify: inter-word;
}

.title {
    color: #ff8139;
    font-size: 2.5rem;
    text-transform: uppercase;
}

.lightTitle {
    color: #000000;
}

.description {
    color: #cccccc;
    letter-spacing: 0.1rem;
    line-height: 1.5rem;
    font-size: 1.1rem;
}

.descriptionClasses{
    margin-bottom: 0;
}

.lightDescription {
    color: black;
}
.text{
    color: #cccccc;
    letter-spacing: 0.1rem;
    line-height: 1.5rem;
    /* font-size: 1rem; */
    text-align: justify;
    text-justify: inter-word;
 
}
@media screen and (min-width: 280px) and (max-width: 1080px) {
    .like {
        margin: 0;
    }

    .container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .content {
        padding: 1rem 2rem;
        border-radius: 0;
    }

    .description {
        font-size: 0.9rem;
    }

    .content:nth-of-type(2) {
        background-color: #111111;
    }

    .title{
        text-align: center;
    }

    .lightContent:nth-of-type(2) {
        background-color: #ffffff;
    }
}