.home {
    position: relative;
    height: 87vh;
    padding-top: 4.3rem;
    margin-top: 1.8rem;
}

.container {
    color: white;
    display: flex;
    justify-content: space-between;
}

.heading {
    font-size: 2.5rem;
    font-weight: 500;
    margin-top: 4rem;
    padding-left: 6rem;
}

.mind,
.zuhaus {
    width: 55%;
    cursor: pointer;
}

.mind img,
.zuhaus img {
    width: 100%;
    height: 22rem;
}

.mind {
    align-self: flex-end;
    overflow: hidden;
}

.zuhaus {
    position: absolute;
    top: 40%;
    left: 1%;
    overflow: hidden;
}

@media only screen and (max-width: 1000px) {
    .home {
        height: 55vh;
        margin-top: 0;
        padding-top: 0;
    }

    .heading {
        padding-left: 3rem;
    }

    .container {
        display: block;
    }

    .container h3 {
        font-size: 2.1rem;
        margin-bottom: 2rem;
    }

    .mind {
        position: absolute;
        right: 0%;
        width: 68%;
    }

    .zuhaus {
        top: 40%;
        width: 68%;
    }

    .mind img,
.zuhaus img {
    height: 10rem;
}
}