.container{
    width: 100%;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 35rem; */
}

.top {
    height: 10%;
    width: 10rem;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: bolder;
    padding: 1rem 0;
}

@media only screen and (max-width: 1000px) {
    .main {
        flex-direction: column-reverse;
    }
}