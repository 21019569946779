.super-rare {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 0;
}

.title-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.title {
    color: #ffffff;
    font-size: 2.4rem;
    text-align: center;
}

.lightTitle {
    color: #000000;
}

.description {
    color: #cccccc;
}

.lightDescription {
    color: #777777;
}

.description a {
    color: #cccccc;
    font-weight: bold;
}

.lightDescription a {
    color: #777777 !important;
}

.cards {
    display: flex;
    justify-content: space-evenly;
    gap: 5rem;
}

.team {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.img {
    height: 15rem;
    width: 13rem;
    background-color: white;
}

.team h2,
.team h4 {
    margin-top: 1rem;
}

.mail {
    color: white;
    font-size: 0.82rem;
    margin-bottom: 0;
    margin-left: 0.3rem;
    text-decoration: none;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
    .super-rare {
        padding: 1rem 2rem;
        width: 100vw;
        margin-bottom: 2rem;
    }

    .cards {
        overflow: auto;
        justify-content: flex-start;
        gap: 2rem;
        flex-direction: column;
    }

    .cards::-webkit-scrollbar {
        display: none;
    }

    .card {
        zoom: 0.7;
    }
}