.container {
  text-align: center;
  margin-bottom: 5rem;
  height: 20rem;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.55),
      rgba(0, 0, 0, 0.55)
    ),
    url("../assets/bg.jpg");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.heading {
  font-size: 2rem;
  width: 100%;
  height: 13.5rem;
  margin-bottom: 0;
}

.wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.round {
  color: white;
  font-size: 4rem;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.897);
  width: 17rem;
  padding: 2rem;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, sans-serif !important;
}

.color {
  background-clip: text;
  font-size: 1.3rem;
  font-family: "Open Sans", sans-serif;
  margin-top: 1.5rem;
  font-weight: 500;
}

.orange {
  color: #ff8139;
  font-weight: bold;
}

@media only screen and (max-width: 1000px) {
  .container {
    height: auto;
    margin-bottom: 0rem;
  }

  .heading {
    height: auto;
    margin-top: 3rem;
  }

  .wrapper {
    flex-direction: column;
  }

  .box {
    margin-bottom: 2rem;
  }

  .orange {
    margin-bottom: 2rem;
  }

  .color {
    font-size: 1.7rem;
  }

  .round {
    width: 17rem;
    font-size: 1.5rem;
    margin-bottom: 0;
  }
}
