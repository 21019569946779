.releases {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.release {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem;
}

.container {
    overflow: hidden;
    box-shadow: 0 0 15px grey;
    width: 100%;
    border-radius: 20px;
}

.img {
    width: 100%;
    /* max-height: 40rem; */
    margin: auto;
    border-radius: 20px;
    overflow: hidden;
}

.content {
    padding-left: 3rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
    padding-right: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    text-align: justify;
    text-justify: inter-word;
}

.title {
    font-size: 2rem;
    text-align: center;
}

.title2 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
}

.description {
    font-size: 1rem;
}

.link {
    color: black;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    gap: 1rem;
    width: max-content;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
}

.link:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: black;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;

}

.link:hover {
    transform: scale(1.05);
}

.link:hover:after {
    width: 100%;
    left: 0;
}

.link svg {
    font-size: 1.2rem;
}

.image {
    position: relative;
    overflow: hidden;
    text-align: center;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 40%;
    margin-bottom: -2rem;
}

.line {
    position: relative;
    display: inline-block;
    /* width: 10.3rem; */
    width: 50%;
    height: 1px;
    background-color: white;
    margin-bottom: 0.6rem;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
    .releases {
        margin: 0;
        gap: 0;
        margin-bottom: 2.5rem;
    }

    .release {
        border-radius: 0;
        grid-template-columns: 1fr;
        padding: 1rem 1rem;
        gap: 2rem;
    }

    .content {
        padding: 0;
        font-size: 0.8rem;
        padding: 0 1rem;
        gap: 0;
    }

    .container {
        width: 100%;
        margin-top: 1rem;
    }

    .link {
        font-size: 1rem;
    }
}