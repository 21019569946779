.releases {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.release {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-position: center;
}

.container {
    overflow: hidden;
    box-shadow: 0 0 15px grey;
    width: 100%;
    border-radius: 10px;
    margin-top: 1rem;
}

.img {
    width: 100%;
    max-height: 32rem;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
}

.content {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    text-align: justify;
    text-justify: inter-word;
}

.title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: rgb(93, 93, 209);
    text-transform: uppercase;
}

.title2 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
}

.description {
    font-size: 1.05rem;
}

.description span {
    font-size: 1.45rem;
    color: rgb(93, 93, 209);
    font-weight: bold;
}


@media screen and (min-width: 280px) and (max-width: 1080px) {
    .releases {
        margin: 0;
        margin-bottom: 2.5rem;
        gap: 0;
    }

    .release {
        border-radius: 0;
        grid-template-columns: 1fr;
        padding: 1rem 1rem;
        gap: 2rem;
    }

    .container {
        width: 100%;
        margin-top: 1rem;
    }

    .content {
        padding: 0;
    }

    .description {
        font-size: 0.8rem;
        padding: 0 1rem;
    }

    .link {
        padding: 0 1rem;
    }

    .title{
        font-size: 2.1rem;
    }
}