.card {
    background-color: #000000;
    width: max-content;
    border-radius: 1.2rem;
}

.lightCard{
    background-color: black;
}

.card-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.card-heading {
    display: flex;
    justify-content: space-between;
}

.card-series {
    color: #ff8139;
    text-transform: uppercase;
    font-size: 0.7rem;
}

.card-top {
    color: #cccccc;
    text-transform: uppercase;
    font-size: 0.7rem;
}

.lightTop {
    color: #777777;
}

.card-details {
    color: #ffffff;
}

.lightDetails {
    color: white;
}

.card-price {
    display: flex;
    gap: 1rem;
}

.card-sub-details {
    display: flex;
    justify-content: space-between;
    text-align: justify;
    text-justify: inter-word;
}

.card-sub-details span {
    color: #cccccc;
    font-size: 0.9rem;
}

.lightSubDetails span{
    color: white;
}

@media only screen and (max-width: 1000px) {
    .card {
        width: 100%;
    }

    .card-image img {
        width: 100%;
    }
}