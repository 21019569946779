.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: none !important;
    margin-bottom: 1rem;
}

.brand-container {
    width: 28%;
}

.brand {
    display: flex;
}

.brand img {
    height: 100%;
}

.toggle-container {
    display: none;
}

.indo {
    color: #cccccc;
    text-align: center;
    margin-top: 0.3rem;
    width: 10rem;
    line-height: 1rem;
    margin-bottom: 0;
    font-size: 0.75rem;
    text-transform: uppercase;
}

.links {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    padding-left: 0;
    margin-bottom: 0;
}

.links li .dark {
    color: black;
}

.links li .light {
    color: yellow;
}

.anchor {
    color: #cccccc;
    text-decoration: none;
    font-size: 1.2rem;
}

.list li:last-of-type a {
    color: #ff3998;
}

.img {
    width: 130%;
    padding-bottom: 0.8rem;
    margin-right: 0.5rem;
}

.img2 {
    width: 85%;
    border-radius: 10%;
}

.dropdown .dropbtn {
    font-size: 1.2rem;
    border: none;
    outline: none;
    color: #cccccc;
    padding: 14px 16px;
    background-color: inherit;
}

.dropdown:hover .dropbtn {
    background-color: hsla(0, 0%, 50%, 0.1);
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    margin-bottom: 0;
    cursor: pointer;
}

.dropdown-content a:hover {
    background-color: hsla(0, 0%, 50%, 0.1);
}

.dropdown:hover .dropdown-content {
    display: block;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
    .container {
        position: relative;
        padding: 1rem 2rem;
    }

    .brand-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .brand img {
        height: 2.5rem;
    }

    .toggle-container {
        display: block;
        color: #ffffff;
        display: flex;
        flex-direction: row-reverse;
        gap: 1rem;
        z-index: 40;
    }

    .toggleLight {
        color: #000000;
    }

    .toggle {
        z-index: 40;
        display: block;
    }

    .links-container {
        z-index: 30;
        /* background-image: linear-gradient(101deg, #ff3998, #ff8139); */
        backdrop-filter: blur(5px);
        height: 100vh;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        width: 0;
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s ease-in-out;
    }

    .links {
        flex-direction: column;
        gap: 0rem;
        width: 100%;
    }

    .links li a {
        color: #111111;
    }

    .light li a {
        color: #ffffff;
    }

    .links li:last-of-type {
        display: none;
    }

    .nav-visible {
        width: 50vw;
        height: 60vw;
        visibility: visible;
        opacity: 1;
        margin-top: 5rem;
    }

    .anchor {
        font-weight: bold;
        font-size: 1.3rem;
        padding: 14px 16px;
    }
    
    .dropdown .dropbtn {
        font-size: 1.3rem;
        font-weight: bold;
    }
}